<template>
<div>
    <el-dialog v-dialogDrag :title="datas.dialogTitle" :visible.sync="datas.dialogVisible" width="60%" :close-on-click-modal="false" append-to-body @close="cleanInputAllValue">
        <div style="display: flex;">
            <span style="line-height:30px;text-align:center;width:100px;">
                {{datas.dialogTitle}}
            </span>
            <el-input @change="changeInputAllValue" size="mini" :min="1" :max="655355" clearable v-model="inputAllValue" placeholder="请输入1-655355"></el-input>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="datas.dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="operatePost()">保 存</el-button>
        </span>
    </el-dialog>
</div>
</template>

<script>
export default {
    data() {
        return {
            dialogVisible: true,
            inputAllValue: '',
            params: {}
        };
    },
    props: ['datas', 'SensorModuleShow'],
    methods: {
        changeInputAllValue() {
            console.log(Number(this.inputAllValue))
            if (Number(this.inputAllValue) > 655355) {
                this.inputAllValue = 655355
            } else if (Number(this.inputAllValue) < 1) {
                this.inputAllValue = 1
            }
        },
        operatePost() {
            // let params={}
            if (this.datas.dialogTitle == "上限流明" || this.datas.dialogTitle == "下限流明" || this.datas.dialogTitle == "流明间隔") {
                this.params.devId = this.datas.params.devId
                this.params.devState = this.datas.dialogTitle
                this.params.order = 0
                if (this.datas.dialogTitle == "上限流明") {
                    this.params.devUpperLimitLumen = this.inputAllValue
                } else if (this.datas.dialogTitle == "下限流明") {
                    this.params.devLowerLimitLumen = this.inputAllValue
                } else if (this.datas.dialogTitle == "流明间隔") {
                    this.params.devLumenSpacing = this.inputAllValue
                    // this.params.sn=this.datas.devSn
                }
            }
            if (this.datas.dialogTitle == "调光上限" || this.datas.dialogTitle == "调光下限" || this.datas.dialogTitle == "调光步长") {
                this.params.devId = this.datas.params.devId
                this.params.devState = this.datas.dialogTitle
                this.params.order = 1
                if (this.datas.dialogTitle == "调光上限") {
                    this.params.upperDimming = this.inputAllValue
                } else if (this.datas.dialogTitle == "调光下限") {
                    this.params.lowerDimming = this.inputAllValue
                } else if (this.datas.dialogTitle == "调光步长") {
                    this.params.dimmingStride = this.inputAllValue
                }
            }
            console.log(this.params)
            this.enabledReq(this.params)
        },
        enabledReq(data) {
            if (data.devState == "流明间隔") {
                this.$axios({
                    method: "put",
                    url: `/common2/deviceInformation/${this.datas.devSn}`,
                    data: {
                        custom: data.devLumenSpacing
                    }
                })
                .then((res) => {
                    this.$message.success("操作成功");
                })
                .catch((err) => {
                    // this.loading4 = false;
                    this.isError(err, this);
                });
            }else {
                this.$axios({
                    method: "post",
                    url: '/common2/devices/operate',
                    data: {
                        devId: data.devId,
                        devLowerLimitLumen: data.devLowerLimitLumen,
                        devState: data.devState,
                        order: data.order,
                        devUpperLimitLumen: data.devUpperLimitLumen
                    }
                })
                .then((res) => {
                    this.$message.success("操作成功");
                })
                .catch((err) => {
                    // this.loading4 = false;
                    this.isError(err, this);
                })
            }
            this.datas.dialogVisible = false;
        },
        cleanInputAllValue() {
            this.inputAllValue = ''
        }

    },
    mounted() {
        console.log(this.datas)
        console.log(this.SensorModuleShow)
    }
}
</script>
